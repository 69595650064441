import { ControlModal, DisplayLoading, HideLoading, BtnCloseModal, BtnForceCloseModal, BodyLoading } from "/js/functions";
import { Service } from '../controlfiber-atc/services/auth-service'

const loadDOM = async (event) => {

      BodyLoading(false)

      const usuarioLogadoString = localStorage.getItem('_usuario_logado')
      const usuarioLogado = JSON.parse(usuarioLogadoString)

      if (usuarioLogadoString != null) {
            
            if ((window.location.pathname == "/") || (window.location.pathname == "/index.html")) {
                  window.location.href = '/pages/mapa/mapa.html'
            }
      }

      //       if ((window.location.href == "http://localhost:5173/") || (window.location.href == "https://authlayerapi.azurewebsites.net")) {
      //             window.location.href = '/pages/mapa/mapa.html'
      //       }
      // }
      
      const form = document.getElementById('frmLogin')

      //##############################################################################################################
      // EVENTO: GET CEP / NÚMERO TELA
      // Details: que irá interceptar o SUBMIT do Busca Endereços
      //##############################################################################################################
      form.addEventListener('submit', (event) => {

            event.preventDefault();

            var txtEmail = document.querySelector("#txtEmail").value
            var txtSenha = document.querySelector("#txtSenha").value

            //Versão 01: Abrir modal de endereços
            // AddressModal.loadModalAddress(cep, numero).then((res) => {
            //     loadEventLinkAddress()
            // })

            BodyLoading(true)

            //Versão 02: Plotar endereços no mapa
            Service.EfetuarLogin(txtEmail, txtSenha)
                  .then((data) => {

                        if (data == 404) {

                              BodyLoading(false)

                              var menu = document.querySelector("#message");

                              var html = `
                              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                              <strong>Credenciais incorretas!</strong> Favor inserir email e senha corretamente.
                              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            `
                              menu.innerHTML = html;
                        }
                        else {

                              //localStorage.setItem('_usuario_logado', JSON.stringify(data), 1000)

                              localStorage.setItem('_usuario_logado', JSON.stringify(data))

                              // localStorage.setItem('_usuario_logado', JSON.stringify({
                              //       "value": data,
                              //       "expires": 1
                              //   }));

                              window.location.href = '/pages/mapa/mapa.html'      
                              
                              // if (txtEmail == 'admin@atc.com') {
                              //       window.location.href = '/pages/mapa/mapa.html'      
                              // }
                              // else{
                              //       window.location.href = '/views/mapa/mapa.html'
                              // }
                              

                        }

                  })
                  .then((data) => {
                        return data
                  })
                  .catch((err) => {
                        BodyLoading(false)
                  })
      });
};

function isObject(obj) {
      return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
};



// Escutadores de evento
window.addEventListener("load", loadDOM);